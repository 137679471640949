<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    mobile-breakpoint="991"
    width="220"
    absolute
    class="z-side-nav"
  >
    <template #default>
      <v-layout
        column
      >
        <v-list-item
          avatar
          class="z-logo"
        >
          <!-- <v-list-item-avatar
            color="white"
          > -->
          <v-img
            :src="logo"
            height="70"
            @click="navigateToDashboard"
          />
        <!-- </v-list-item-avatar>
          <v-list-item-title class="title">
            Zwono
          </v-list-item-title> -->
        </v-list-item>
        <v-list-item
          v-for="(link, i) in links"
          :key="i"
          :to="link.to"
          :active-class="color"
          avatar
          class="v-list-item"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title v-text="link.text" />
        </v-list-item>
      </v-layout>
    </template>
    
    <template
      #append
    >
      <v-img
        :src="euImage"
        class="eu-image"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from 'vuex';

export default {
  data: () => ({
    // eslint-disable-next-line no-undef
    logo: require('@/assets/img/Zwono_Logo-H-Colors-RGB-228x70.svg'),
    euImage: require('@/assets/img/Logo_EU.svg'),
    links: [],
    responsive: false,
    role: '',
  }),
  computed: {
    ...mapState('drawer', ['color','drawer']),
    inputValue: {
      get() {
        return this.$store.state.drawer.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    items() {
      return this.$t('Layout.View.items');
    },
  },
  created() {
    // Set main menu
    let link = {};
    // this.$t('Home.')
    link = {
      to: '/dashboard?main',
      icon: 'mdi-view-dashboard',
      text: this.$t('Home.mainmenu_Dashboard'),
    };
    this.links.push(link);
    (link = {
      to: '/dashboard/drivers',
      icon: 'mdi-car',
      text: this.$t('Home.mainmenu_Drivers'),
    }),
      this.links.push(link);
    link = {
      to: '/dashboard/products',
      icon: 'mdi-toolbox',
      text: this.$t('Home.mainmenu_Products'),
    };
    this.links.push(link);
    link = {
      to: '/dashboard/customers',
      icon: 'mdi-face-agent',
      text: this.$t('Home.mainmenu_Customers'),
    };
    this.links.push(link);
    link = {
      to: '/dashboard/timeslots',
      icon: 'mdi-account-clock-outline',
      text: this.$t('Home.mainmenu_Timeslots'),
    };
    this.links.push(link);
    link = {
      to: '/dashboard/deliveries',
      icon: 'mdi-gift-open-outline',
      text: this.$t('Home.mainmenu_Deliveries'),
    };
    this.links.push(link);
    link = {
      to: '/dashboard/orders',
      icon: 'mdi-bookmark-outline',
      text: this.$t('Home.mainmenu_Orders'),
    };
    this.links.push(link);
    link = {
      to: '/dashboard/vehicles',
      icon: 'mdi-car-estate',
      text: this.$t('Home.mainmenu_Vehicle'),
    };
    this.links.push(link);
    // If user.role === ADMIN or SUPER_ADMIN, show Company link in main menu
    if (
      sessionStorage.getItem('role') === 'SUPER_ADMIN'
    ) {
      let company_link = {
        to: '/dashboard/companies',
        icon: 'mdi-factory',
        text: this.$t('Home.mainmenu_Companies'),
      };
      this.links.splice(1, 0, company_link);
    } 
    if (
      sessionStorage.getItem('role') === 'ADMIN' ||
      sessionStorage.getItem('role') === 'SUPER_ADMIN'
    ) {
      let user_link = {
        to: '/dashboard/users',
        icon: 'mdi-account-check',
        text: this.$t('Home.mainmenu_Users'),
      };
      this.links.splice(2, 0, user_link);
    }
  },
  initialize() {},
  methods: {
    // imports and spreads the mutations into their own variables, easier than importing one by one
    ...mapMutations('drawer', ['setDrawer', 'toggleDrawer']),

    navigateToDashboard() {
      this.$router.push({ path: '/dashboard' });
    }
  },
};
</script>

<style lang="scss">
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }

  .v-navigation-drawer__append {
    margin-top: auto;
    .eu-image {
      margin: 16px
    }
  }
}
</style>
