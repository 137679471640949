/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import Vue from 'vue';
import Vuex from 'vuex';
import { stringify as queryStringify } from 'query-string'

Vue.use(Vuex);

const state = {
    companyList: [],
    companyListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
}

//need to create some getters and replace some code in the drawer component files with this
const getters = {
    companyList: state => state.companyList,
    companyListMeta: state => state.companyListMeta,
};

// actions
const actions = {
    getCompanyList({ commit }, paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                company_id,
                order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                sortBy: paginationOptions.sortBy[0] || '',
                page: paginationOptions.page,
                take:paginationOptions.itemsPerPage,
                q: paginationOptions.search || '',
            }

        if (!queryParams?.company_id) delete queryParams.company_id
        
        const queryString = queryStringify(queryParams);

        return axiosInstance.get(`/companies?${queryString}`)
            .then(response => {
                if (response.status === 200) {
                    const companies = response.data.data;
                    const meta = response.data.meta
                    commit('setCompanyList', { companies, meta })
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Company list error: ' + error)
                }
                return false
            })
    },
    createCompany({ commit }, company) {
        if(company.contact_person_phone === "") delete company.contact_person_phone;

        return axiosInstance.post('/companies', company)
            .then(response => {
                if (response.status === 200) {
                    if (response.data?.id) company.id = response.data.id
                    commit('addCompanyToList', company)
                    commit('set_search_address_api', company.search_address_api, { root: true });
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('company.createCompany() error: ' + e);
                }
                return false;
            })
    },
    updateCompany({ commit }, company) {
        if(company.contact_person_phone === "") company.contact_person_phone = null;

        return axiosInstance.put('/companies', company)
            .then(response => {
                if (response.status === 200) {
                    commit('editCompanyInList', company)
                    commit('set_search_address_api', company.search_address_api, { root: true });
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('company.updateCompany() error: ' + e);
                }
                return false;
            })
    },
    deleteCompany({ commit }, id) {
        return axiosInstance.delete(`/companies/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteCompanyFromList', id)
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('company.deleteCompany() error: ' + e);
                }
                return false;
            })
    },
    getAvatar({ commit }, id) {
        return axiosInstance
            .get(`/companies/avatar/${id}`, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    var content_type = response.headers['content-type'];
                    const buffer = Buffer.from(response.data, 'binary').toString('base64');
                    return `data:${content_type};charset=utf-8;base64,` + buffer;
                }
                return null
            })
            .catch((e) => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('company.getAvatar() error: ' + e);
                }
                return null
            });
    },
    setAvatar({ commit }, {image, companyId}) {
        try {
            let formData = new FormData();
            formData.append('avatar', image);
            return axiosInstance({
                url: `/companies/avatar/${companyId}`,
                data: formData,
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    if (response.status === 200) return true
                    return false
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('company.setAvatar() error: ' + e);
                    }
                    return false;
                })
        } catch (err) {
            if (process.env.NODE_ENV === 'development') {
                console.log('company.setAvatar() error: ' + err);
            }
            return false;
        }
    },
    clearCompanyState({ commit }) {
        commit('clearState', state);
    },
}

//mutations
const mutations = {
    // setters
    setCompanyList(state, companyList) {
        state.companyList = companyList.companies
        state.companyListMeta = companyList.meta
    },

    // other
    deleteCompanyFromList(state, companyId) {
        state.companyList = state.companyList.filter(c => c.id !== companyId)
    },
    addCompanyToList(state, company) {
        state.companyList.unshift(company)
    },
    editCompanyInList(state, company) {
        state.companyList = state.companyList.map(c => {
            if (c.id === company.id) {
                return company
            } return c
        })
    },

    // clear state
    clearState(state) {
        state.companyList = null
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};