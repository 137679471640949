<template>
  <material-card
    v-bind="$attrs"
    class="v-card--material-stats"
    v-on="$listeners"
  >
    <v-card-title
      class="text-h5 z-card-table-title"
    >
      {{ $t('Home.deliveries_in_progress') }}
    </v-card-title>
    <v-card
      slot="offset"
      :class="`elevation-${elevation}`"
      :color="color"
      class="pa-4"
      dark
    >
      <v-icon
        size="40"
      >
        {{ icon }}
      </v-icon>
    </v-card>
    <div class="z-delivery-progress-info justify-space-between d-md-flex d-lg-inline-block">
      <div class="z-delivery-info-group mb-1 me-lg-1 mb-md-0 mb-lg-1">
        <strong class="z-delivery-info-label">{{ $t('Dashboard.delivery_progress_date') }}:</strong> 
        <v-chip
          color="zwonogray"
          label
        >
          {{ data.delivery_date }}
        </v-chip>
      </div>
      <div class="z-delivery-info-group mb-1 mb-md-0 mb-lg-1">
        <strong class="z-delivery-info-label">{{ $t('Dashboard.delivery_progress_vehicle') }}:</strong> 
        <v-chip
          color="zwonoyellow"
          label
        >
          {{ data.vehicle_registration }}
        </v-chip>
      </div>
      <div class="z-delivery-info-group mb-1 me-lg-1 mb-md-0">
        <strong class="z-delivery-info-label">{{ $t('Dashboard.delivery_progress_driver') }}:</strong> 
        <v-chip
          color="zwonogreen"
          label
        >
          {{ data.driver_full_name }}
        </v-chip>
      </div>
      <div class="z-delivery-info-group mb-1 mb-md-0">
        <strong class="z-delivery-info-label">{{ $t('Common.phone') }}:</strong> 
        <v-chip
          color="zwonogreen"
          label
        >
          {{ data.driver_phone }}
        </v-chip>
      </div>
    </div>
    <v-data-table
      :loading-text="$t('Common.loading-text')"
      :headers="headers"
      :items="data.orders"
      class="elevation-1"
    >
      <template #[`item.full_alternative_address`]="{ item }">
        <template
          v-if="item.alternative_address"
        >
          {{ callMapFullAlternativeAddress(item.alternative_address) }}
        </template>
      </template>
      <template #[`item.status`]="{ item }">
        <v-icon
          v-if="item.status === 'Ordered' "
          medium
          class="mr-2"
          color="zwonoyellow"
        >
          mdi-progress-question
        </v-icon>
        <v-icon
          v-if="item.status === 'InProgress' "
          medium
          class="mr-2"
          color="orange"
        >
          mdi-progress-clock
        </v-icon>
        <v-icon
          v-if="item.status === 'Delivered' "
          medium
          class="mr-2"
          color="zwonogreen"
        >
          mdi-progress-check
        </v-icon>
        <v-icon
          v-if="item.status === 'NotDelivered' "
          medium
          class="mr-2"
          color="red"
        >
          mdi-progress-close
        </v-icon>
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import Card from './Card'
import { mapFullAlternativeAddress } from "../../helpers/helpers.js"

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    },
    smallValue: {
      type: String,
      default: undefined
    },
    data: {
      type: Object,
      default: undefined
    },
    headers: {
      type: Array,
      default: undefined
    }
  },
  methods: {
    callMapFullAlternativeAddress(addressObj) {
      return mapFullAlternativeAddress(addressObj)
    }
  }
}
</script>

<style lang="scss">
.v-card--material-stats {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .v-offset {
    display: inline-block;
    flex: 0 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
    max-width: auto;
    padding: 0 16px 0;
  }

  .v-card {
    border-radius: 4px;
    flex: 0 1 auto;
  }

  .v-card__text {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  .v-card__actions {
    flex: 1 0 100%;
  }
}
</style>
