import axiosInstance from '../plugins/axiosInstance'

export const fetchAddressSuggestions = async(searchText, searchAddressApi) => {
    if(searchText) {
      const apiUrl = `/misc/autocomplete?text=${searchText}&searchAddressApi=${searchAddressApi}`;
      try {
        const response = await axiosInstance.get(apiUrl);
        const apiType = response?.data?.apiType;
        switch(apiType) {
          case 'Planplus': {
            // eslint-disable-next-line no-case-declarations
            const responseObject = { lastSnp: { number: null }, autocompleteLocationObject: null, addressSuggestions: [] }
            responseObject.lastSnp = response.data.snp || { number: null };
            responseObject.autocompleteLocationObject = response.data?.locationObject || null
            responseObject.addressSuggestions = response.data?.results?.map( loc => {
              const label = 
                loc.title + 
                (responseObject?.lastSnp?.number ? ` ${responseObject.lastSnp.number}` : '') +
                (loc.address ? (loc.title ? `, ${loc.address}` : `${loc.address}`) : '')
              loc.apiType = apiType

              return { label, value: loc }; 
            });
            return responseObject;
            }
          case 'Geoapify': {
              const responseObject = { lastSnp: null, autocompleteLocationObject: null, addressSuggestions: [] }
              responseObject.autocompleteLocationObject = response.data?.locationObject || null
              responseObject.addressSuggestions = response.data?.results?.map( loc => {
                const label = 
                  loc.title +
                  (loc.address ? (loc.title ? `, ${loc.address}` : `${loc.address}`) : '')
                loc.apiType = apiType

                return { label, value: loc }; 
              });
              return responseObject;
           }
          default:
            break;
        }
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          console.log('fetchAddressSuggestions error: ', e);
        }
      }
    }
  }

  export const searchLocationPlanPlus = async(searchText) => {
    const apiUrl = `/misc/searchLocationPlanPlus?text=${searchText}`;
    try {
      const response = await axiosInstance.get(apiUrl);
     if ('0' in response.data) return response.data['0']
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.log('searchLocationPlanPlus error: ', e);
      }
    }
  }

  export const getLocationObject = async(id, searchAddressApi) => {
    const apiUrl = `/misc/locationObject?id=${id}&searchAddressApi=${searchAddressApi}`;
    try {
      const response = await axiosInstance.get(apiUrl);
      return response.data
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.log('getLocationObject error: ', e);
      }
    }
  }

  export const getStreetPlanPlus = async(id, number) => {
    const apiUrl = `/misc/getStreetPlanPlus?id=${id}&number=${number}`;
    try {
      const response = await axiosInstance.get(apiUrl);
      return response.data
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.log('getStreetPlanPlus error: ', e);
      }
    }
  }