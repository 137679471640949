<template>
  <v-dialog
    v-model="dialog"
    max-width="290"
  >
    <v-card class="text-center">
      <v-card-title 
        class="text-h5 justify-center"
      >
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ text }}
      </v-card-text>

      <!-- eslint-disable-next-line vue/html-self-closing-->
      <slot name="content"></slot>

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="confirmText"
          color="green darken-1"
          text
          @click="confirmClick()"
        >
          {{ confirmText }}
        </v-btn>
        <v-btn
          v-if="cancelText"
          color="green darken-1"
          text
          @click="dialog = false"
        >
          {{ cancelText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      dialog: this.isVisible,
    }
  },
  watch: {
    isVisible (value) {
      this.dialog = value
    },
    dialog (value) {
      this.$emit('is-visible', value)
    }
  },
  methods: {
    confirmClick: function () {
      this.$emit('confirm-click')
    }
  }
}
</script>