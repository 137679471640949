import axiosInstance from '@/plugins/axiosInstance'

// state
const state = {
    orderStats: {},
    productStatsCount30Days: {},
    customerStatsTop5Orders: {},
    customerStatsTop5Paying: {},
}

// getters
const getters = {
    orderStats: state => state.orderStats,
    productStatsCount30Days: state => state.productStatsCount30Days,
    customerStatsTop5Orders: state => state.customerStatsTop5Orders,
    customerStatsTop5Paying: state => state.customerStatsTop5Paying,
};

// actions
const actions = {
    getOrderStatsForCompany({ commit }) {
        try {
            // Get company_id
            const company_id = sessionStorage.getItem('company_id');
            // Get stats

            return axiosInstance.get(`/stats/order/${company_id}`)
                .then(response => {
                    if (response.status === 200) {
                        const orderStatsResult = response.data.stats
                        let orderStats = {
                            labels: [],
                            series: []
                        };
                        for (var item of orderStatsResult) {
                            orderStats.labels.push(item.month + '/' + item.year);
                            orderStats.series.push(item.qty);
                        }
                        commit('setOrderStats', { orderStats })
                        return true
                    } return false

                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('stats.getOrderStatsForCompany() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('stats.getOrderStatsForCompany() error: ' + e);
            }
            return false
        }
    },
    getMostOrderedItemsIn30DaysForCompany({ commit }) {
        try {
            // Get company_id
            const company_id = sessionStorage.getItem('company_id');
            // Get stats

            return axiosInstance.get(`/stats/mostordered/${company_id}`)
                .then(response => {
                    if (response.status === 200) {
                        const productStatsResult = response.data
                        let productStatsCount30Days = {
                            labels: [],
                            series: []
                        };
                        for (var item of productStatsResult) {
                            productStatsCount30Days.labels.push(item.product_name);
                            productStatsCount30Days.series.push(item.count);
                        }
                        commit('setProductStatsCount30Days', { productStatsCount30Days })
                        return true
                    } return false
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('stats.getMostOrderedItemsIn30DaysForCompany() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('stats.getMostOrderedItemsIn30DaysForCompany() error: ' + e);
            }
            return false
        }

    },
    getCustomerTop5Orders({ commit }) {
        try {
            // Get company_id
            const company_id = sessionStorage.getItem('company_id');
            // Get stats

            return axiosInstance.get(`/stats/customertop5ordercount/${company_id}`)
                .then(response => {
                    if (response.status === 200) {
                        const customerStatsResult = response.data
                        let customerStatsTop5Orders = {
                            labels: [],
                            series: []
                        };
                        for (var item of customerStatsResult) {
                            customerStatsTop5Orders.labels.push(item.customer_name);
                            customerStatsTop5Orders.series.push(item.number_of_orders);
                        }
                        commit('setCustomerStatsTop5Orders', { customerStatsTop5Orders })
                        return true
                    } return false
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('stats.getCustomerTop5Orders() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('stats.getCustomerTop5Orders() error: ' + e);
            }
            return false
        }
    },
    getCustomerTop5Paying({ commit }) {
        try {
            // Get company_id
            const company_id = sessionStorage.getItem('company_id');
            // Get stats

            return axiosInstance.get(`/stats/customertop5paying/${company_id}`)
                .then(response => {
                    if (response.status === 200) {
                        const customerStatsResult = response.data
                        let customerStatsTop5Paying = {
                            labels: [],
                            series: []
                        };
                        for (var item of customerStatsResult) {
                            customerStatsTop5Paying.labels.push(item.customer_name);
                            customerStatsTop5Paying.series.push(item.sum_orders);
                        }
                        commit('setCustomerStatsTop5Paying', { customerStatsTop5Paying })
                        return true
                    } return false

                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('stats.getCustomerTop5Paying() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('stats.getCustomerTop5Paying() error: ' + e);
            }
            return false
        }
    },

    clearStatsState({ commit }) {
        commit('clearState', state);
    },
};

//mutations
const mutations = {
    // setters
    setOrderStats(state, orderStats) {
        state.orderStats = orderStats.orderStats
    },
    setProductStatsCount30Days(state, productStatsCount30Days) {
        state.productStatsCount30Days = productStatsCount30Days.productStatsCount30Days
    },
    setCustomerStatsTop5Orders(state, customerStatsTop5Orders) {
        state.customerStatsTop5Orders = customerStatsTop5Orders.customerStatsTop5Orders
    },
    setCustomerStatsTop5Paying(state, customerStatsTop5Paying) {
        state.customerStatsTop5Paying = customerStatsTop5Paying.customerStatsTop5Paying
    },

    // other

    // clear state
    clearState(state) {
        state.orderStats = {};
        state.productStatsCount30Days = {};
        state.customerStatsTop5Orders = {};
        state.customerStatsTop5Paying = {};
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};