<template>
  <v-toolbar
    id="core-toolbar"
    dark
    prominent
    class="z-toolbar"
  >
    <div class="v-toolbar-title d-flex align-center">
      <v-toolbar-title
        class="pb-0"
      >
        <v-btn
          v-if="responsive"
          class="v-btn--simple my-0"
          large
          icon
          @click.stop="onClickBtn"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <!-- {{ title }} -->
      </v-toolbar-title>
      <v-img
        v-if="responsive"
        :src="logoWhite"
        height="44"
        width="199"
        class="z-logo-white"
      />
    </div>
    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2
      >
        <v-chip
          class="z-user-name ma-2"
        >
          <v-avatar
            v-if="avatar"
          >
            <img :src="avatar">
          </v-avatar>
          <v-avatar 
            v-else
            color="primary"
          >
            <v-icon dark>
              mdi-account-circle
            </v-icon>
          </v-avatar>
          {{ fullname }}  ({{ company_name }})
        </v-chip>
        <router-link
          v-ripple
          class="toolbar-items"
          to="/dashboard"
        >
          <v-icon color>
            mdi-home
          </v-icon>
        </router-link>
        <!-- <v-menu
          bottom
          left
          content-class
          offset-y
          transition="slide-y-transition"
        >
          <template #activator="{ on }">
            <router-link
              v-ripple
              class="toolbar-items"
              to="/dashboard/notifications"
              v-on="on"
            >
              <v-badge
                color="error"
                overlap
              >
                <template slot="badge">
                  {{ notifications.length }}
                </template>
                <v-icon color>
                  mdi-bell
                </v-icon>
              </v-badge>
            </router-link>
          </template>
          <v-card>
            <v-list dense>
              <v-list-tile
                v-for="notification in notifications"
                :key="notification"
                @click="onClick"
              >
                <v-list-tile-title v-text="notification" />
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu> -->
        <router-link
          v-ripple
          class="toolbar-items"
          to="/dashboard/user-profile"
        >
          <v-icon color>
            mdi-account
          </v-icon>
        </router-link>

        <v-icon
          class="toolbar-items z-btn-logout"
          color
          @click="setLogoutModalVisibility(true)"
        >
          mdi-power
        </v-icon>
        <logout-modal
          :is-visible="isLogoutModalVisible"
          @is-visible="value => setLogoutModalVisibility(value)"
        />
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import LogoutModal from '../../components/modals/LogoutModal.vue';

export default { 
  components: {
    LogoutModal,
  },
  data: () => ({
    // notifications: [
    //   'Mike, Thanos is coming',
    //   '5 new avengers joined the team',
    //   'You're now friends with Capt',
    //   'Another Notification',
    //   'Another One - Dj Khalid voice'
    // ],
    title: '',
    responsive: false,
    responsiveInput: false,
    fullname: '',
    company_name: '',
    langs: ['en', 'de'],
    avatar: '',
    logoWhite: require('@/assets/img/Zwono_Logo-H-White-RGB-190x50.svg'),
    isLogoutModalVisible: false
  }),

  watch: {
    $route (val) {
      this.title = val.meta.name
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.title = this.$t('Common.title')
  },

  async created () {
    this.fullname = sessionStorage.getItem('fullname');
    this.company_name = sessionStorage.getItem('company_name');
    this.avatar = await this.getAvatar();
  },
  
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapActions('user', ['getAvatar']),
    ...mapMutations('drawer', ['setDrawer', 'toggleDrawer']),
    onClickBtn () {
      this.setDrawer(!this.$store.state.drawer.drawer);
    },
    onClick () {
      //
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    },
    setLogoutModalVisibility(isVisible) {
      this.isLogoutModalVisible = isVisible
    }   
  }
}
</script>

<style>
	#core-toolbar a {
		text-decoration: none;
	}
</style>
