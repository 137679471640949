/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'

const state = {
    customerPreferenceList: [],
};

// getters
const getters = {
    customerPreferenceList: state => state.customerPreferenceList,
};

// actions
const actions = {
    getCustomerPreferenceList({ commit }, company_id) {
        let params = "";
        if (company_id) params += `?company_id=${company_id}`;
        return axiosInstance
            .get(`/customer_preferences${params}`)
            .then((response) => {
                if (response.status === 200) {
                    const customer_preferences = response.data.data;
                    commit("setCustomerPreferenceList", { customer_preferences });
                    return true;
                }
                return false;
            })
            .catch((e) => {
                if (process.env.NODE_ENV === "development") {
                    console.log(
                        "customer_preference.getCustomerPreferenceListForCompany() error: " +
                        e
                    );
                }
                return false;
            });
    },
    createCustomerPreference({ commit }, pref) {
        try {
            pref.company_id = sessionStorage.getItem("company_id");
            return axiosInstance
                .post("/customer_preferences", pref)
                .then((response) => {
                    if (response.status !== 200) return false;
                    if (response.data?.id) pref.id = response.data.id;
                    commit('addCustomerPreferenceToList', pref)
                    return true;
                })
                .catch((e) => {
                    if (process.env.NODE_ENV === "development") {
                        console.log(
                            "customer_preference.createCustomerPreference() error: " + e
                        );
                    }
                    return false;
                });
        } catch (e) {
            if (process.env.NODE_ENV === "development") {
                console.log(
                    "customer_preference.createCustomerPreference() error: " + e
                );
            }
            return false;
        }
    },
    updateCustomerPreference({ commit }, pref) {
        return axiosInstance
            .put("/customer_preferences", pref)
            .then((response) => {
                if (response.status === 200) return true;
                return false;
            })
            .catch((e) => {
                if (process.env.NODE_ENV === "development") {
                    console.log(
                        "customer_preference.updateCustomerPreference() error: " + e
                    );
                }
                return false;
            });
    },
    deleteCustomerPreference({ commit }, id) {
        return axiosInstance
            .delete(`/customer_preferences/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    commit('deleteCustomerPreferenceFromList', id)
                    return true
                }
                return false
            })
            .catch((e) => {
                if (process.env.NODE_ENV === "development") {
                    console.log(
                        "customer_preference.deleteCustomerPreference() error: " + e
                    );
                }
                return false;
            });
    },
    clearCustomerPreferenceState({ commit }) {
        commit("clearState", state);
    },
};

//mutations
const mutations = {
    // setters
    setCustomerPreferenceList(state, customerPreferenceList) {
        state.customerPreferenceList = customerPreferenceList.customer_preferences;
    },

    //other
    addCustomerPreferenceToList(state, customerPreference) {
        state.customerPreferenceList.unshift(customerPreference)
    },
    deleteCustomerPreferenceFromList(state, customerPrefId) {
        state.customerPreferenceList = state.customerPreferenceList.filter(c => c.id !== customerPrefId)
    },
    clearState(state) {
        state.customerPreferenceList = null;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
