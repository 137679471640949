export default {
  primary: '#39a860',
  secondary: '#4caf50',
  tertiary: '#495057',
  accent: '#82B1FF',
  error: '#f55a4e',
  info: '#00d3ee',
  success: '#5cb860',
  warning: '#ffa21a',
  general: '#2196F3',
  zwonogreen: '#39a860',
  zwonogreendark: '#309c56',
  zwonogreendarker: '#288f4c',
  zwonogray: '#999999',
  zwonograydark: '#737373',
  zwonograydarker: '#363636',
  zwonoyellow: '#dbc42d',
  zwonoyellowdark: '#cfb92b',
  zwonoyellowdarker: '#c2ad29',
}
