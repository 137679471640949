<template>
  <v-snackbar
    v-model="showSnackbar"
    :color="color"
    bottom
    right
  >
    <v-icon left>
      {{ icon }}
    </v-icon>{{ message }}
    <template #action="{ attrs }">
      <v-btn
        :color="closeColor"
        text
        v-bind="attrs"
        @click="showSnackbar = false"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Vtoast",
  data() {
    return {
      showSnackbar: false,
      message: "",
      color: "success",
      closeColor: "white",
      icon: "mdi-check",
    };
  },
  methods: {
    show(data) {
      this.message = data.message || 'missing "message".';
      this.color = data.color || "success";
      this.closeColor = data.closeColor || "white";

      if (data.color === "error" && !data.icon) {
        this.icon = "mdi-alert-circle-outline";
      } else {
        this.icon = data.icon || "mdi-check";
      }

      this.showSnackbar = true;
    },
  },
};
</script>
