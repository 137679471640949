/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import { stringify as queryStringify } from 'query-string'

// state
const state = {
    vehicleList: [],
    vehicleListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
}

// getters
const getters = {
    vehicleList: state => state.vehicleList,
    vehicleListMeta: state => state.vehicleListMeta,
};

// actions
const actions = {
    getVehicleList({ commit }, paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                company_id,
                order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                sortBy: paginationOptions.sortBy[0] || '',
                page: paginationOptions.page,
                take:paginationOptions.itemsPerPage,
                q: paginationOptions.search || '',
            }

        if (!queryParams.company_id) delete queryParams.company_id
        
        const queryString = queryStringify(queryParams);

        return axiosInstance.get(`/vehicles?${queryString}`)
            .then(response => {
                if (response.status === 200) {
                    const vehicles = response.data.data
                    const meta = response.data.meta
                    commit('setVehicleList', { vehicles, meta })
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Vehicle list error: ', error)
                }
                return false
            })
    },
    createVehicle({ commit }, vehicle) {
        vehicle.company_id = sessionStorage.getItem('company_id');
        return axiosInstance.post('/vehicles', vehicle)
            .then(response => {
                if (response.status === 200) {
                    if (response?.data.id) vehicle.id = response.data.id;
                    commit('addVehicleToList', vehicle)
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Create vehicle error: ', error)
                }
                return false
            })
    },
    updateVehicle({ commit }, vehicle) {
        if (!vehicle.company_id) {
            vehicle.company_id = sessionStorage.getItem('company_id');
        }
        return axiosInstance.put('/vehicles', vehicle)
            .then(response => {
                if (response.status === 200) {
                    commit('editVehicleInList', vehicle)
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Update vehicle error: ', error)
                }
                return false
            })
    },
    deleteVehicle({ commit }, id) {
        return axiosInstance.delete(`/vehicles/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteVehicleFromList', id)
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Delete vehicle error: ', error)
                }
                return false
            })
    },

    clearVehicleState({ commit }){
        commit('clearState', state);
    },
};

//mutations
const mutations = {
    // setters
    setVehicleList(state, vehicleList) {
        state.vehicleList = vehicleList.vehicles,
        state.vehicleListMeta = vehicleList.meta
    },

    // other
    deleteVehicleFromList(state, vehicleId) {
        state.vehicleList = state.vehicleList.filter(c => c.id !== vehicleId)
    },
    addVehicleToList(state, vehicle) {
        state.vehicleList.unshift(vehicle)
    },
    editVehicleInList(state, delivery) {
        state.vehicleList = state.vehicleList.map(c => {
            if (c.id === delivery.id) {
                return delivery
            } return c
        })
    },

    // clear state
    clearState(state) {
        state.vehicleList = [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};