/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import { stringify as queryStringify } from 'query-string'

// Helper function to convert base64 string to Blob
const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
}

// state
const state = {
    deliveryList: [],
    deliveryListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    generateDeliveryReportStatus: false,
    mapFromCsvList: [],
    deliveriesInProgressList: [],
    dispatchToWarehouseStatus: false,
    deliveryStatusItems: [
        { value: 'Delivered', label: 'Delivered' },
        { value: 'InProgress', label: 'In Progress' },
        { value: 'NotDelivered', label: 'Not Delivered' },
        { value: 'Planned', label: 'Planned' },
    ]
};

// getters
const getters = {
    deliveryList: state => state.deliveryList,
    deliveryListMeta: state => state.deliveryListMeta,
    generateDeliveryReportStatus: state => state.generateDeliveryReportStatus,
    mapFromCsvList: state => state.mapFromCsvList,
    deliveriesInProgressList: state => state.deliveriesInProgressList,
    dispatchToWarehouseStatus: state => state.dispatchToWarehouseStatus,
    deliveryStatusItems: state => state.deliveryStatusItems,
};

// actions
const actions = {
    getDeliveriesList({ commit },paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                company_id,
                order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                sortBy: paginationOptions.sortBy[0] || '',
                page: paginationOptions.page,
                take:paginationOptions.itemsPerPage,
                q: paginationOptions.search || '',
            }

        if (!queryParams?.company_id) delete queryParams.company_id
        
        const queryString = queryStringify(queryParams);

        return axiosInstance.get(`/deliveries?${queryString}`)
            .then(response => {
                if (response.status === 200) {
                    let deliveries = response.data.data
                    const meta = response.data.meta
                    deliveries.forEach(element => {
                        element.timeslots = element.timeslots.map(x => x.id);
                    });
                    commit('setDeliveryList', { deliveries, meta })
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('delivery.getDeliveriesList() error: ' + e);
                }
                return false
            })
    },
    createDelivery({ commit }, delivery) {
        try {
            delivery.company_id = sessionStorage.getItem('company_id');
            delivery.timeslot_ids = delivery?.timeslots;

            return axiosInstance.post('/deliveries', delivery)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data?.id) delivery.id = response.data.id
                        if (response.data?.driver) delivery.driver = response.data.driver
                        if (response.data?.vehicle) delivery.vehicle = response.data.vehicle
                        commit('addDeliveryToList', delivery)
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('delivery.createDelivery() error: ' + e);
                    }
                    return false
                })

        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('delivery.createDelivery() error: ' + e);
            }
            return false
        }
    },
    updateDelivery({ commit }, delivery) {
        try {
            delivery.company_id = delivery?.company?.id ? delivery.company.id : sessionStorage.getItem('company_id');
            delivery.timeslot_ids = delivery?.timeslots;

            return axiosInstance.put('/deliveries', delivery)
                .then(response => {
                    if (response.status === 200) {
                        commit('editDeliveryInList', delivery)
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('delivery.updateDelivery() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('delivery.updateDelivery() error: ' + e);
            }
            return false
        }
    },
    deleteDelivery({ commit }, id) {
        return axiosInstance.delete(`/deliveries/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteDeliveryFromList', id)
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('delivery.deleteDelivery() error: ' + e);
                }
                return false
            })
    },
    dispatchToWarehouse({ commit }, delivery_id) {
        return axiosInstance.post(`/deliveries/dispatch/${delivery_id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setDispatchToWarehouseStatus', true);
                    commit('setDeliveryIsDispatchedTrue', delivery_id)
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('delivery.dispatchToWarehouse() error: ' + e);
                }
                commit('setDispatchToWarehouseStatus', false);
                return false
            })
    },
    async downloadDispatchPdf({ commit }, deliveryId) {
        try {
            // Make a request to get the PDF as a base64 string
            const response = await axiosInstance.get(`/deliveries/downloadDispatchPdf/${deliveryId}`);
            if (response.status === 200 && response.data) {
                // Assuming the response is a JSON object { pdf: "<base64String>" }
                const base64String = response.data.pdf;
                
                // Convert base64 to blob and create a download link
                const link = document.createElement("a");
                const blob = base64ToBlob(base64String, 'application/pdf');
                link.href = URL.createObjectURL(blob);
                link.download = `DeliveryReport-${deliveryId}.pdf`;
                
                // Append to the DOM and trigger download
                document.body.appendChild(link);
                link.click();
                
                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
                return true;
            } else {
                console.error('Failed to download the PDF.');
                return false;
            }
        } catch (error) {
            console.error('Error downloading the dispatch PDF:', error);
            return false;
        }
    },

    generateDeliveryReport({ commit }, delivery_id) {
        return axiosInstance.post(`/deliveries/report/${delivery_id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('setGenerateDeliveryReportStatus', true);
                    return true;
                }
                return false;
            }).catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('delivery.generateDeliveryReport() error: ' + e);
                }
                commit('setGenerateDeliveryReportStatus', false);
                return false
            })
    },
    getDeliveriesInProgress({ commit }) {
        return axiosInstance.get('/deliveries/inprogress')
            .then(response => {
                if (response.status === 200) {
                    const deliveriesInProgress = response.data
                    commit('setDeliveriesInProgressList', { deliveriesInProgress })
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('delivery.getDeliveriesInProgress() error: ' + e);
                }
                return false
            })
    },

    notifyOrdersEndclientWithoutStatus({ commit }, delivery_id) {
        return axiosInstance.get(`/deliveries/orders_notify/${delivery_id}`)
            .then(response => {
                if (response.status === 200) {
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('delivery.notifyOrdersEndclientWithoutStatus() error: ' + e);
                }
                return false
            })
    },

    getDeliveriesInProgressSSE({ commit }, sse) {
        sse.create({
            url: process.env.VUE_APP_API_URL + '/deliveries/inprogress-sse',
            format: 'json',
            polyfill: true,
            withCredentials: false,
            forcePolyfill: true,
            polyfillOptions: {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
            }
        })
            .on('message', (msg) => {
                commit('setDeliveriesInProgressList', { deliveriesInProgress: msg })
            })
            .on('error', (err) => {
            if (process.env.NODE_ENV === 'development') {
                console.log('/deliveries/inprogress-sse Failed to parse or lost connection:', err)
            }
            })
            .connect()
            .catch((err) => {
            if (process.env.NODE_ENV === 'development') {
                console.log('/deliveries/inprogress-sse Failed make initial connection:', err)
            }
            })
    },

    parseCsv({ commit }, params) {
        return axiosInstance.post('/deliveries/csv', params)
            .then(response => {
                if (response.status === 200) {
                    const mapFromCsvList = response.data;
                    commit('setMapFromCsvList', { mapFromCsvList });
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('delivery.parseCsv() error: ' + e);
                }
                return false
            })
    },
    clearDeliveryState({ commit }) {
        commit('clearState', state);
    }
};

//mutations
const mutations = {
    // setters
    setDeliveryList(state, deliveries) {
        state.deliveryList = deliveries.deliveries
        state.deliveryListMeta = deliveries.meta
    },
    setDispatchToWarehouseStatus(state, status) {
        state.dispatchToWarehouseStatus = status;
    },
    setDeliveriesInProgressList(state, deliveriesInProgress) {
        state.deliveriesInProgressList = deliveriesInProgress.deliveriesInProgress;
    },

    setMapFromCsvList(state, mapFromCsvList) {
        state.mapFromCsvList = mapFromCsvList;
    },
    setGenerateDeliveryReportStatus(state, status) {
        state.generateDeliveryReportStatus = status;
    },

    //called in order.js
    setDeliveryStatusInList(state, delivery) {
        state.deliveryList = state.deliveryList.map(d => {
            if (d.id === delivery.delivery_id) {
                d.status = delivery.status
            } return d
        })
    },

    // other
    deleteDeliveryFromList(state, deliveryId) {
        state.deliveryList = state.deliveryList.filter(c => c.id !== deliveryId)
    },
    addDeliveryToList(state, delivery) {
        state.deliveryList.unshift(delivery)
    },
    editDeliveryInList(state, delivery) {
        Object.assign(state.deliveryList.find(d => d.id === delivery.id), delivery);
    },
    setDeliveryIsDispatchedTrue(state, deliveryId) {
        const foundDelivery = state.deliveryList.find(d => d.id === deliveryId)
        foundDelivery.is_dispatched = true;
    },
    clearState(state) {
        state.deliveryList = [];
        state.mapFromCsvList = [];
        state.deliveriesInProgressList = [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};