/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import { stringify as queryStringify } from 'query-string'

// state
const state = {
    driverList: [],
    driverListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
}

// getters
const getters = {
    driverList: state => state.driverList,
    driverListMeta: state => state.driverListMeta,
};

// actions
const actions = {
    getDriverList({ commit }, paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                company_id,
                order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                sortBy: paginationOptions.sortBy[0] || '',
                page: paginationOptions.page,
                take:paginationOptions.itemsPerPage,
                q: paginationOptions.search || '',
            }

        if (!queryParams?.company_id) delete queryParams.company_id
        
        const queryString = queryStringify(queryParams);

        return axiosInstance.get(`/drivers?${queryString}`)
            .then(response => {
                if (response.status === 200) {
                    const drivers = response.data.data
                    const meta = response.data.meta

                    commit('setDriverList', { drivers, meta })
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('driver.getDriverList() error: ' + e);
                }
                return false
            })
    },
    updateDriver({ commit }, driver) {
        try {
            const driverCopy = Object.assign({}, driver)
            driverCopy.company_id = driverCopy?.company?.id;
            driverCopy.user_id = driverCopy?.user?.id;
            driverCopy.delivery_duration = driverCopy?.delivery_duration * 60;

            delete driverCopy?.company;
            delete driverCopy?.user;
            return axiosInstance.put('/drivers', driverCopy)
                .then(response => {
                    if (response.status === 200) {
                        commit('editDriverInList', driverCopy)
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('driver.updateDriver() error: ' + e);
                    }
                    return false;
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('driver.updateDriver() error: ' + e);
            }
            return false
        }
    },
    deleteDriver({ commit }, id) {
        return axiosInstance.delete(`/drivers/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteDriverFromList', id)
                    return true;
                }
                return false;
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('driver.deleteDriver() error: ' + e);
                }
                return false
            })
    },
    clearDriverState({ commit }) {
        commit('clearState', state);
    }
};

//mutations
const mutations = {
    // setters
    setDriverList(state, driverList) {
        state.driverList = driverList.drivers
        state.driverListMeta = driverList.meta
    },

    // other
    deleteDriverFromList(state, driverId) {
        state.driverList = state.driverList.filter(c => c.id !== driverId)
    },
    addDriverToList(state, driver) {
        state.driverList.unshift(driver)
    },
    editDriverInList(state, driver) {
        state.driverList = state.driverList.map(c => {
            if (c.id === driver.id) {
                return {...c, ...driver}
            } return c
        })
    },
    clearState(state) {
        state.driverList = [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};