import axiosInstance from '@/plugins/axiosInstance'
import i18n from '../i18n/index'
import router from '@/router'

export default {
    login({ commit }, userData) {
        commit('auth_request')
        return axiosInstance.post('/auth/login', { email: userData.username, password: userData.password })
            .then(response => {
                if (response.status === 200) {
                    const token = response.data.token.access_token;
                    const refreshToken = response.data.token.refresh_token;
                    const user = response.data.user;
                    const search_address_api = user?.company?.search_address_api || '';
                    const fullname = user.fullName;
                    const company_id = user.company ? user.company.id : null;
                    const company_name = user.company ? user.company.company_name : '/';
                    const role = user.role;
                    const lang = user.lang;

                    if (token) sessionStorage.setItem('token', token);
                    if (refreshToken) sessionStorage.setItem('refreshToken', refreshToken);
                    sessionStorage.setItem('user', JSON.stringify(user));
                    sessionStorage.setItem('search_address_api', search_address_api);
                    if (fullname) sessionStorage.setItem('fullname', fullname);
                    if (company_id) sessionStorage.setItem('company_id', company_id);
                    if (company_name) sessionStorage.setItem('company_name', company_name);
                    if (role) sessionStorage.setItem('role', role);
                    if (lang) {
                        i18n.locale = lang;
                    }

                    commit('auth_success', { token, refreshToken, user, company_id, role, search_address_api });
                    return true;
                } else {
                    commit('auth_error');
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('refreshToken');
                    sessionStorage.removeItem('user');
                    return false;
                }
            })
            .catch(() => {
                commit('auth_error');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                sessionStorage.removeItem('user');
                return false;
            });
    },
    logout({ commit }) {
        const refreshToken = sessionStorage.getItem('refreshToken');
        axiosInstance.post('/auth/logout', { refreshToken })
            .then(() => {
                commit('logout');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('fullname');
                sessionStorage.removeItem('company_id');
                sessionStorage.removeItem('role');
                sessionStorage.removeItem('company_name');
                router.push('/')
            })
            .catch(error => {
                commit('logout');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('fullname');
                sessionStorage.removeItem('company_id');
                sessionStorage.removeItem('role');
                sessionStorage.removeItem('company_name');
                this.$router.push('/')
                if (process.env.NODE_ENV === 'development') {
                    console.log('logout: ' + error);
                }
            });
    },
    refreshtoken({ commit }) {
        return axiosInstance.post('/auth/refresh', { refreshToken: sessionStorage.getItem('refreshToken') })
            .then(response => {
                const accessToken = response.data.access_token;
                const refreshToken = response.data.refresh_token;
                if (accessToken) sessionStorage.setItem('token', accessToken);
                if (refreshToken) sessionStorage.setItem('refreshToken', refreshToken);
                commit('refresh_success', { token: accessToken, refreshToken });
                return accessToken;
            })
            .catch(error => {
                commit('logout');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                sessionStorage.removeItem('user');
                if (process.env.NODE_ENV === 'development') {
                    console.log('refreshtoken: ' + error);
                }
            });
    },
};