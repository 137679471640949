/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'
import { stringify as queryStringify } from 'query-string'
import vue from 'vue';

// state
const state = {
    userList: [],
    userListMeta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false
    },
    roles: [],
    me: {},
}

// getters
const getters = {
    userList: (state) => state.userList,
    userListMeta: (state) => state.userListMeta,
    roles: (state) => state.roles,
    me: (state) => state.me,
};

// actions
const actions = {
    getCurrentUser({ commit }) {
        return axiosInstance.get(`/auth/me`)
            .then(response => {
                if (response.status === 200) {
                    const me = response.data
                    commit('setMe', me)
                    return true
                }
                return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('user.getCurrentUser() error: ' + e);
                }
                return false
            })
    },
    async getUserList({ commit }, paginationOptions) {
        const company_id = paginationOptions.role !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        const queryParams = paginationOptions.itemsPerPage === 'all' 
            ? {
                take: 100,
                company_id
            }
            : {
                    company_id,
                    order: (paginationOptions.sortDesc[0] ? 'DESC' : 'ASC') || '',
                    sortBy: paginationOptions.sortBy[0] || '',
                    page: paginationOptions.page,
                    take:paginationOptions.itemsPerPage,
                    q: paginationOptions.search || '',
                }

            if (!queryParams.company_id) delete queryParams.company_id
            
            const queryString = queryStringify(queryParams);

            return axiosInstance.get(`/users?${queryString}`)
                .then(response => {
                    if (response.status === 200) {
                        const users = response.data.data;
                        const meta = response.data.meta
                        commit('setUserList', { users, meta })
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('user.getUserList() error: ' + e);
                    }
                    return false
                })
    },
    createUser({ commit }, user) {
        if (user.phone === "") delete user.phone;
        const company_id = user.loggedUserRole !== "SUPER_ADMIN" ? sessionStorage.getItem("company_id") : ""
        delete user.loggedUserRole
        if (company_id) {
            user.company_id = company_id
        }

        return axiosInstance.post('/auth/register', user)
            .then(response => {
                if (response.status === 200) {
                    if (response.data?.id) user.id = response.data.id
                    commit('addUserToList', user)
                    return true
                }
                return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('user.createUser() error: ' + e);
                }
                return false
            })
    },
    updateUser({ commit, rootState }, user) {
        if (user.phone === "") delete user.phone;
        if (!user.password) delete user.password;
        
        return axiosInstance.put('/users', user)
            .then(response => {
                if (response.status === 200) {
                    if (user?.id === rootState.me?.id) {
                        commit('setMe', user)
                    }
                    commit('editUserInList', user)
                    return true
                }
                return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('user.updateUser() error: ' + e);
                }
                return false
            })
    },
    deleteUser({ commit }, id) {
        return axiosInstance.delete(`/users/${id}`)
            .then(response => {
                if (response.status === 200) {
                    commit('deleteUserFromList', id)
                    return true
                }
                return false
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('user.deleteUser() erroor:', error)
                }
                return false
            })
    },
    getRoles({ commit }) {
        return axiosInstance.get(`/auth/roles`)
            .then(response => {
                if (response.status == 200) {
                    const roles = response.data
                    commit('setRoles', { roles })
                    return true
                }
                return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('user.getRoles() error: ' + e);
                }
                return false
            })
    },
    getAvatar({ commit }, id) {
        return axiosInstance
            .get(`/users/avatar`, { responseType: 'arraybuffer' })
            .then((response) => {
                if (response.status === 200) {
                    const buffer = Buffer.from(response.data, 'binary').toString('base64');
                    var content_type = response.headers['content-type'];
                    return `data:${content_type};charset=utf-8;base64,` + buffer
                }
                return null
            }
            )
            .catch((e) => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('user.getAvatar() error: ' + e);
                }
                return null
            });
    },
    setAvatar({ commit }, image) {
        try {
            let formData = new FormData();
            formData.append('avatar', image);
            return axiosInstance({
                url: `/users/avatar`,
                data: formData,
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        return true
                    }
                    return false
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('user.setAvatar() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('user.setAvatar() error: ' + e);
            }
            return false
        }
    },

    clearUserState({ commit }) {
        commit('clearState', state);
    },
};

//mutations
const mutations = {
    // setters
    setMe(state, user) {
        state.me = user
    },

    setRoles(state, roles) {
        state.roles = roles.roles;
    },

    setUserList(state, userList) {
        vue.set(state, 'userList', userList.users);
        vue.set(state, 'userListMeta', userList.meta);
        //state.userList = userList.users
        //state.userListMeta = userList.meta
    },

    // other
    deleteUserFromList(state, userId) {
        state.userList = state.userList.filter(c => c.id !== userId)
    },
    addUserToList(state, user) {
        state.userList.unshift(user)
    },
    editUserInList(state, user) {
        state.userList = state.userList.map(c => {
            if (c.id === user.id) {
                return user
            } return c
        })
    },

    // clear state
    clearState(state) {
        state.userList = [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};