<template>
  <v-main
    class="z-login"
  >
    <v-container
      fill-height
      fluid
    >
      <v-layout
        justify-center
      >
        <v-flex
          xs12
          sm8
          md4
        >
          <v-img
            :src="logo"
            max-height="100"
            class="z-logo my-4"
          />
          <v-card
            class="z-login-form-body"
          >
            <v-toolbar
              color="zwonogreen"
              class="z-login-title"
            >
              <v-toolbar-title>{{ $t("Login.title") }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text
              class="z-login-form-content"
            >
              <v-form>
                <v-text-field
                  ref="username"
                  v-model="username"
                  :rules="[() => !!username || $t('Common.forms.field-required')]"
                  prepend-icon="mdi-account"
                  :label="$t('Login.label-login')"
                  placeholder="Username or email"
                  required
                />
                <v-text-field
                  ref="password"
                  v-model="password"
                  :rules="[() => !!password || $t('Common.forms.field-required')]"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :label="$t('Login.label-password')"
                  placeholder="*********"
                  counter
                  required
                  @keydown.enter="login"
                  @click:append="showPassword = !showPassword"
                />
              </v-form>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center mt-4 mb-5"
            >
              <v-btn
                color="zwonograydarker z-btn-login"
                x-large
                @click="login"
              >
                <v-icon left>
                  mdi-login-variant
                </v-icon>
                {{ $t("Login.login-btn") }}
              </v-btn>
            </v-card-actions>
            <v-snackbar
              v-model="snackbar"
              :color="color"
              :top="true"
            >
              {{ errorMessages }}
              <v-btn
                dark
                text
                @click="snackbar = false"
              >
                {{ $t("Common.close") }}
              </v-btn>
            </v-snackbar>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: function () {
    return {
      username: '',
      password: '',
      errorMessages: '',
      snackbar: false,
      color: 'general',
      showPassword: false,
      logo: require('@/assets/img/Zwono_Logo-H-Colors-RGB-228x70.svg'),
    }
  },
  methods: {
    login: function () {
      let username = this.username
      let password = this.password
      
      if(username && password) {
        this.$store.dispatch('login', { username, password })
          .then(() => { 
            this.$router.push('/dashboard')
          }).catch(err => {
            if (err?.response?.status === 404) this.errorMessages = this.$t('Login.unauthorized-error')
            else this.errorMessages = this.$t('Common.errors.unknown-error')
            this.snackbar = true 
          })
      } else {
        this.errorMessages = this.$t('Login.no-user-data-error')
        this.snackbar = true 
      }
    }
  },
}
</script>
