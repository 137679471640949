<template>
  <v-dialog
    v-model="dialog"
    max-width="1200"
  >
    <v-card>
      <v-card-title class="text-h5 z-card-title">
        <v-icon left>
          mdi-truck-fast-outline
        </v-icon>
        {{ title }}
        <v-spacer />
        <v-btn
          icon
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-autocomplete
          ref="autocompleteRef"
          v-model="selectedAddress"
          :items="addressSuggestions"
          :no-filter="true"
          :label="$t('Customer.enter_new_address')"
          :search-input.sync="searchText"
          item-text="label"
          :return-object="true"
          :hide-no-data="true"
          :loading="isLoading"
          :no-data-text="$t('Common.no_data')"
        />
        <MapLibre
          v-if="isVisible"
          :markers="selectedCoordinates ? [selectedCoordinates] : []"
        />
        <div
          v-if="alertText"
          class="alert-container mt-5"
        >
          <v-alert
            dense
            type="error"
          >
            {{ alertText }}
          </v-alert>
        </div>
        <v-card-actions
          class="d-flex justify-center"
        >
          <v-btn
            class="mx-0"
            color="zwonogreen"
            :loading="isSaving"
            :disabled="!selectedCoordinates"
            @click="saveBtnClik"
          >
            {{ $t("Common.select") }}
          </v-btn>
          <v-btn 
            class="mx-0 ml-5"
            color="warning"
            :loading="isSaving"
            @click="cancelBtnClick"
          >
            {{ $t("Common.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import MapLibre from "@/components/maplibre/MapLibre.vue";
import { fetchAddressSuggestions, searchLocationPlanPlus, getLocationObject, getStreetPlanPlus } from '../../helpers/address-search-helpers.js'
import { mapGetters } from "vuex";

export default {
  components: {
    MapLibre,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    isSaving: {
      type: Boolean,
      default: false,
      required: true
    },
    searchAddressApi: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      required: true
    },
    prefill: {
      type: String,
      default: null,
      required: false
    },
  },
  data () {
    return {
      dialog: this.isVisible,
      searchText:  null,
      selectedAddress: null,
      selectedCoordinates: null,
      foundLocation: null,
      searchedLocation: null,
      lastSnp: null,
      autocompleteLocationObject: null,
      addressSuggestions: [],
      isLoading: false,
      alertText: '',
    }
  },
  computed: {
    ...mapGetters(["getSearchAddressApi"]),
  },
  watch: {
    isVisible (value) {
      this.dialog = value
      if(this.isVisible && this.prefill) {
        this.fetchAddressSuggestions(this.prefill)
      }
    },
    dialog (value) {
      this.$emit('is-visible', value)
    },
    searchText (text) {
      if(this.isVisible && text) this.fetchAddressSuggestions(text, this.searchAddressApi || this.getSearchAddressApi)
    },
    async selectedAddress(value) {
      let isLocationFound = false;
      const apiType = value?.value?.apiType
          switch(apiType) {
            case 'Planplus':
              if (value?.value?.code && value?.value?.type) {
                const type = value?.value?.type
                let location = null;
                switch (type) {
                  case 1:
                    location = await this.getStreetPlanPlus(value?.value?.code, this.lastSnp.number)
                    break;
                  case 2:
                    location = await this.getLocationObject(value?.value?.code, this.searchAddressApi || this.getSearchAddressApi);
                    break;
                  default:
                    break;
                }
                if (location) {
                      const coordinates = (location.lat && (location.lon || location.lng)) ? { lat: location.lat, lon: location.lon || location.lng } : null
                      this.selectedCoordinates = coordinates;
                      isLocationFound = !!coordinates
                      this.foundLocation = location;
                      if (value?.label) this.searchedLocation = await this.searchLocationPlanPlus(value.label);
                } else if (this.autocompleteLocationObject) {
                  const coordinates = ( this.autocompleteLocationObject.lat && ( this.autocompleteLocationObject.lon || this.autocompleteLocationObject.lng))
                    ? { lat: this.autocompleteLocationObject.lat, lon: this.autocompleteLocationObject.lon || this.autocompleteLocationObject.lng }
                    : null
                  
                  this.selectedCoordinates = coordinates;
                  isLocationFound = !!coordinates
                  this.foundLocation = this.autocompleteLocationObject;
                }
              } 
              
              if (!isLocationFound && value?.label) {
                const location = this.searchedLocation;
                const coordinates = (location.lat && (location.lon || location.lng)) ? { lat: location.lat, lon: location.lon || location.lng } : null
                this.selectedCoordinates = coordinates;
              }
              break;
            case 'Geoapify':
            if (value?.value?.code) {
                const location = await this.getLocationObject(value?.value?.code, this.searchAddressApi || this.getSearchAddressApi);
                if (location) {
                  const coordinates = (location.lat && (location.lon || location.lng)) ? { lat: location.lat, lon: location.lon || location.lng } : null
                  
                  this.selectedCoordinates = coordinates;
                  isLocationFound = !!coordinates
                  this.foundLocation = location;
                } else if (this.autocompleteLocationObject) {
                  const coordinates = ( this.autocompleteLocationObject.lat && ( this.autocompleteLocationObject.lon || this.autocompleteLocationObject.lng))
                    ? { lat: this.autocompleteLocationObject.lat, lon: this.autocompleteLocationObject.lon || this.autocompleteLocationObject.lng }
                    : null
                  
                  this.selectedCoordinates = coordinates;
                  isLocationFound = !!coordinates
                  this.foundLocation = this.autocompleteLocationObject;
                }
              } 
              break;
            default:
              break;
          }
    }
  },
  methods: {
    async fetchAddressSuggestions(searchText, searchAddressApi) {
      if(searchText) {
        this.isLoading = true;
        const res = await fetchAddressSuggestions(searchText, searchAddressApi)
        this.isLoading = false;
        this.lastSnp = res.lastSnp;
        this.addressSuggestions = res.addressSuggestions;
        this.autocompleteLocationObject = res.autocompleteLocationObject;
        return res;
      }
    },
    async searchLocationPlanPlus(searchText) {
      this.isLoading = true;
        const res = await searchLocationPlanPlus(searchText)
        this.isLoading = false;
        return res;
    },
    async getLocationObject(id, searchAddressApi) {
      this.isLoading = true;
        const res = await getLocationObject(id, searchAddressApi)
        this.isLoading = false;
        return res;
    },
    async getStreetPlanPlus(id, number) {
      this.isLoading = true;
        const res = await getStreetPlanPlus(id, number)
        this.isLoading = false;
        return res;
    },
    async saveBtnClik() {
      const addressObj = {
        country: this.foundLocation?.country || this.foundLocation?.apiType === 'Planplus' ? 'RS' : null,
        city: this.foundLocation?.city || null,
        postal_code: this.foundLocation?.postal_code || this.searchedLocation?.postalCode || null,
        address1:  this.foundLocation?.address1 || this.searchedLocation?.title || null,
        house_number: this.foundLocation?.house_number  || null,
        appartment_details:  this.foundLocation?.appartment_details || null,
        lat: this.selectedCoordinates.lat,
        lon: this.selectedCoordinates.lon,
      }
      if (!addressObj.city && this.lastSnp?.place) addressObj.city = this.lastSnp?.place
      if (!addressObj.address1 && this.lastSnp?.street) addressObj.address1 = this.lastSnp.street
      if (!addressObj.house_number && this.lastSnp?.number) addressObj.house_number = this.lastSnp?.number

      this.$emit('address-object', addressObj)
    },

    async cancelBtnClick() {
      this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
      this.searchText= null
      this.selectedAddress= null
      this.selectedCoordinates= null
      this.foundLocation= null
      this.searchedLocation= null
      this.lastSnp= null
      this.autocompleteLocationObject= null
      this.addressSuggestions= []
      this.isLoading= false
      this.alertText= ''
    }
  }
}
</script>