import Vue from 'vue'
import './components'
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
//import NProgress from "nprogress";
import vuetify from './plugins/vuetify'
import ImageUploader from 'vue-image-upload-resize'
import VueSSE from 'vue-sse';

import 'maplibre-gl/dist/maplibre-gl.css'

const dotenv = require('dotenv')

dotenv.config();
Vue.use(ImageUploader);
Vue.use(VueSSE);
// Sync store with router
sync(store, router)


Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')