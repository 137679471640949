/* eslint-disable no-unused-vars */
import axiosInstance from '@/plugins/axiosInstance'

// state
const state = {
    endClientData: null,
    specialOfferProducts: [],
    endclientStatusItems: [
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'Declined', label: 'Declined' },
        { value: null, label: 'No status yet' }
    ]
}

// getters
const getters = {
    endClientData: state => state.endClientData,
    specialOfferProducts: state => state.specialOfferProducts,
    endclientStatusItems: state => state.endclientStatusItems,
};

// actions
const actions = {
    getEndClientData({ commit }, orderId) {
        try {
            const url = `/orders/endclient/${orderId}`;
            return axiosInstance.get(url)
                .then(response => {
                    if (response.status === 200) {
                        const endClientData = response.data;
                        commit('setEndClientData', { endClientData })
                        return true;
                    }
                    return false;
                })
                .catch(e => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('endclient.getOrder() error: ' + e);
                    }
                    return false
                })
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('endclient.getOrder() error: ' + e);
            }
            return false
        }

    },
    getSpecialOfferProductsForOrder({ commit }, orderId) {
        try {
            const url = `/orders/endclient/${orderId}/specialoffer`;
            return axiosInstance.get(url)
                .then(async response => {
                    if (response.status === 200) {
                        const specialOfferProducts = response.data;
                        if (specialOfferProducts && specialOfferProducts.length > 0) {
                            const imageFetchPromises = specialOfferProducts.map(off => {
                                const imageFetchUrl = `/products/image/${off.id}`;
                                return axiosInstance.get(imageFetchUrl, { responseType: 'arraybuffer' })
                                    .then(response => {
                                        if (response.status === 200) {
                                            const contentType = response.headers['content-type'];
                                            const buffer = Buffer.from(response.data, 'binary').toString('base64');
                                            const imageBase64 = `data:${contentType};charset=utf-8;base64,` + buffer;
                                            off.imageBase64 = imageBase64;
                                        }
                                    })
                                    .catch(e => {
                                        if (process.env.NODE_ENV === 'development') {
                                            console.log('endclient.getSpecialOfferProductsForOrder() error: ' + e);
                                        }
                                        // Handle image fetch error (e.g., could set a default image)
                                    });
                            });
    
                            // Wait for all image fetch promises to resolve
                            await Promise.all(imageFetchPromises);
    
                            // After all images have been fetched, commit the mutation
                            commit('setSpecialOfferProducts', { specialOfferProducts });
                            return true;
                        }
                        return false;
                    }
                    return false;
                });
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('endclient.getSpecialOfferProductsForOrder() error: ' + e);
            }
            return false;
        }
    },
    
    updateStatus({ commit }, order) {
        axiosInstance.post('/orders/endclient', order)
            .then(response => {
                if (response.status === 200) {
                    commit('setEndClientStatus', order.end_client_status)
                    return true;
                }
                return false
            })
            .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('endclient.getSpecialOfferProductsForOrder() error: ' + e);
                }
                return false
            })
    },
    clearEndClientState({ commit }) {
        commit('clearState', state);
    }
};

//mutations
const mutations = {
    // setters
    setEndClientData(state, endClientData) {
        state.endClientData = endClientData.endClientData
    },

    setEndClientStatus(state, status) {
        state.endClientData.endclient_status = status
    },

    setSpecialOfferProducts(state, specialOfferProducts) {
        state.specialOfferProducts = specialOfferProducts.specialOfferProducts
    },

    // other
    addEndClientItem(state, item) {
        // Check if item exists already, then we just increase the qty
        let stateItem = state.endClientData.items.find(i => i.id === item.id)
        if(stateItem){
            stateItem.qty = parseInt(stateItem.qty) + parseInt(item.qty)
            return;
        }
        
        state.endClientData.items.push(item);
    },

    removeEndClientItem(state, itemId) {
        state.endClientData.items = state.endClientData.items.filter(i => i.id !== itemId);
    },

    clearState(state) {
        state.endClientData = null;
        state.specialOfferProducts = [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};